import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import ControlPanelPropietario from "./components/controlPanel/propietario/cProp";
import ControlPanelRepresentante from "./components/controlPanel/representante/cRepr";
import ControlPanelAdministrador from "./components/controlPanel/administrador/cAdmin";
import Videoconference from "./components/videoconferencia/videoconference";
import "./juntas.css";

export default function Juntas() {
  const { user } = useParams();
  const [srcForm, setSrcForm] = useState("");
  const [srcForm2, setSrcForm2] = useState("https://portal.r2docuo.com/abogadosymas/forms/juntaacceso?embed=1");
  const forms = [
    "https://portal.r2docuo.com/abogadosymas/forms/votopresencial", //administrador
    "https://portal.r2docuo.com/abogadosymas/forms/votorepresentante", //representante
    "https://portal.r2docuo.com/abogadosymas/forms/votopropietario", //propietario
  ];
  const embed = "?embed=1";

  function handleClick(action, point) {
    if (action === "registro") {
      if (point === 1) {
        //ADMINISTRADOR
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistentepresencial"
        );
      } else if (point === 2) {
        //REPRESENTANTE
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistenciarepresentante"
        );
      } else {
        //PROPIETARIO
        setSrcForm(
          "https://portal.r2docuo.com/abogadosymas/forms/asistenciapropietarioonline"
        );
      }
    } else if (action === 'start'){
      setSrcForm2('https://portal.r2docuo.com/abogadosymas/forms/juntaacceso?embed=1')
    } else if (action === 'continue'){
      setSrcForm2('https://portal.r2docuo.com/abogadosymas/forms/continuarjunta?embed=1')
    } else if (action === 'votoPropietario'){
      var selectorValue = document.querySelector('#selectVotacion').value
      setSrcForm(forms[2] + 'punto' + selectorValue + 'beta' + embed)
    } else {
      if (user === "administrador") {
        setSrcForm(forms[0] + action + point + embed);
      } else if (user === "representante") {
        setSrcForm(forms[1] + action + point + embed);
      } else if (user === "propietario") {
        setSrcForm(forms[2] + action + point + embed);
      }
    }
  }

  return (
    <div>
      <Navbar />
      <div className="juntas-container">
        {user === "administrador" && (
          <ControlPanelAdministrador handleClick={handleClick} />
        )}
        {user === "propietario" && (
          <ControlPanelPropietario handleClick={handleClick} />
        )}
        {user === "representante" && (
          <ControlPanelRepresentante handleClick={handleClick} />
        )}
        <div className="juntas-vista-panel">
          <div id="videoconferencia">
            <Videoconference />
          </div>
          {user === "administrador" && (
            <iframe
              src={srcForm2}
              title="formulario de control"
              className="junta-form-action"
            />
          )}
          {(user === 'propietario') && (
            <div id="selector-container">
              <select id="selectVotacion" onChange={() => handleClick('votoPropietario')}>
                <option className='optionsSelector' style={{display : 'none'}}>Selecciona el punto a votar...</option>
                <option className='optionsSelector' value="1">Punto 1</option>
                <option className='optionsSelector' value="2">Punto 2</option>
                <option className='optionsSelector' value="3">Punto 3</option>
                <option className='optionsSelector' value="4">Punto 4</option>
                <option className='optionsSelector' value="5">Punto 5</option>
                <option className='optionsSelector' value="6">Punto 6</option>
                <option className='optionsSelector' value="7">Punto 7</option>
                <option className='optionsSelector' value="8">Punto 8</option>
                <option className='optionsSelector' value="9">Punto 9</option>
                <option className='optionsSelector' value="10">Punto 10</option>
                <option className='optionsSelector' value="11">Punto 11</option>
                <option className='optionsSelector' value="12">Punto 12</option>
              </select>
            </div>
          )}
          <iframe
            src={srcForm}
            title="formulario de accion"
            className="junta-form-action"
          />
        </div>
      </div>
    </div>
  );
}
