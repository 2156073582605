import React from "react";
import {Session} from 'bc-react-session'
import Navbar from "./components/Navbar/Navbar";
import AdminPanel from "./components/admin/admin";
import PropPanel from "./components/prop/prop";

export default function Dashboard() {
  const {payload} = Session.get();
  const userType = payload.type;


  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        {userType === "admin" && <AdminPanel />}
        {userType === "owner" && <PropPanel /> }
      </div>
    </div>
  );
}
