import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Session } from "bc-react-session";
import logo from "../../../../assets/images/conmiapp_logo.jpeg";
import "./Navbar.css";

function Navbar() {
  const history = useHistory();
  const session = Session.get();
  const { payload } = Session.get();

  if (!session.isValid) history.push("/login");

  function logOut() {
    Session.destroy();
    history.push("/");
  }

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-logo-container">
          <Link to="/">
            <img src={logo} className="app-logo" alt="Conmiapp Logo" />
          </Link>
        </div>
        <div className="navbar-menu-container">
          <ul className="navbar-menu-list">
            {session && (
              <li className="navbar-menu-list-item">
                <h3>Hola, {payload.name}</h3>
              </li>
            )}
            <li className="navbar-menu-list-item">
              <Link to="/">Inicio</Link>
            </li>
            {session && (
              <li className="navbar-menu-list-item">
                <button onClick={() => logOut()}>Salir</button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
