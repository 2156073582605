import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import axios from "axios";
import "./newpassword.css";

function NewPassword() {
  const code = useParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let rawCode = code.code.split("=");
    let token = rawCode[1];
    if (
      password !== "" &&
      password.length >= 12 &&
      password === confirmPassword
    ) {
      axios
        .post("https://backend.convoco.online/auth/reset-password", {
          code: token,
          password: password,
          passwordConfirmation: confirmPassword,
        })
        .then(() => {
          alert("Ha cambiado su contraseña correctamente");
          history.push("/");
        })
        .catch((err) => {
          setSendEmail(false);
          setError(err.response.data.message[0].messages[0].message);
        });
    } else if (password.length > 12) {
      setError("La contraseña ha de ser de 12 o más caracteres");
    } else if (password === "") {
      setError("La contraseña no puede estar vacía");
    } else {
      setError("Las contraseñas no coinciden");
    }
  };

  const handleChange = (option, value) => {
    setError("");
    switch (option) {
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="newPassword-general">
        <Navbar />
        <div className="newPassword-container">
          {!sendEmail && (
            <>
              <h2 className="newPassword-title">Recuperación de cuenta</h2>
              <form onSubmit={(e) => handleSubmit(e)} id="newPassword-form">
                <section>
                  <label>Contraseña:&nbsp;</label>
                  <br />
                  <input
                    className="newPassword-input"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                  <label>Confirmar contraseña:&nbsp;</label>
                  <br />
                  <input
                    className="newPassword-input"
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </section>
                <button className="newPassword-button">Recuperar cuenta</button>
                <div className="newPassword-error-container">
                  <p className="newPassword-error">{error}</p>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NewPassword;
