import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "./components/navbar/navbar";
import "./reset.css";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email !== "" && isValidEmail(email)) {
      axios
        .post("https://backend.convoco.online/auth/forgot-password", {
          email: email,
        })
        .then(() => {
          setSendEmail(true);
        })
        .catch((err) => {
          setSendEmail(false);
          setError(err.response.data.message[0].messages[0].message);
        });
    } else {
      setError("El email no tiene un formato válido");
    }
  };

  const handleChange = (value) => {
    setError("");
    setEmail(value);
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <>
      <div className="reset-general">
        <Navbar />
        <div className="reset-container">
          {!sendEmail && (
            <>
              <h2 className="reset-title">Recuperación de cuenta</h2>
              <p className="reset-text-description">
                Por favor, indiquenos el e-mail con el que se registró para
                poder remitirle las instrucciones de recuperación de su cuenta
              </p>
              <form onSubmit={(e) => handleSubmit(e)}>
                <section>
                  <label>E-mail:&nbsp;</label>
                  <br />
                  <input
                    className="reset-input"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder="jromeromontañes@gmail.com"
                  />
                </section>
                <button className="reset-button">Recuperar cuenta</button>
                <div className="reset-error-container">
                  <p className="reset-error">{error}</p>
                </div>
              </form>
            </>
          )}
          {sendEmail && (
            <>
              <h2>
                En breve recibirá un correo al e-mail indicado donde se le
                detallará como establecer una nueva contraseña
              </h2>
              <Link to="/" className="reset-button">
                Ir al inicio
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
