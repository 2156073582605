import React from "react";
import ReactDOM from "react-dom";
import { Session } from "bc-react-session";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import swConfig from "./swConfig";

Session.start({
  payload: {},
  expiration: 21600000,
});
Session.onExpiration(() => Session.destroy());

ReactDOM.render(
    <App />,
  document.getElementById("conmiapp")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
