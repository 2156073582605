import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/conmiapp_logo.jpeg";
import "./navbar.css";

function Navbar() {
  const body = document.getElementsByTagName("body")[0];

  const onLoad = () => {
    body.classList.add("juntas-body");
  };

  const clearBody = () => {
    body.classList.remove("juntas-body");
  };

  return (
    <div className="junta-navbar-container" onLoad={onLoad}>
      <Link to="/" onClick={() => clearBody()}>
        <img src={logo} className="junta-navbar-logo" alt="Conmiapp Logo" />{" "}
      </Link>
    </div>
  );
}

export default Navbar;
