import React from "react";
import './label.css';

const Label = ({ text }) => {
  return (
    <div className="label-login-container">
      <label className="label-login">{text}</label>
    </div>
  );
};

export default Label;
