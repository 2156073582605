import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/conmiapp_logo.jpeg";
import "./navbar.css";

function Navbar() {
  return (
    <div className="newPassword-navbar-container">
      <Link to="/">
        <img src={logo} className="newPassword-navbar-logo" alt="Conmiapp Logo" />{" "}
      </Link>
    </div>
  );
}

export default Navbar;
